<template>
    <div class="color-txt">
        <s-form @submit="addItem" ref="form">
            <h2 class="head font-poppins-semibold mt-3">Collection Schedule</h2>
            <div class="row mt-4">
                <div class="col-2">
                    <validated-vue-select class="c-input-select field-required"
                                          :options="typeOptions" :rules="{required:true}"
                                          label="Type" v-model="model.collection_type"></validated-vue-select>

                </div>
                <div class="col-2 ">
                    <validated-ajax-vue-select class="c-input-select field-required"
                                               :url="shedOptionURL" @input="loadBatchOption"
                                               label="Shed" v-model="model.shed_number " :rules="{required:true}"></validated-ajax-vue-select>
                </div>
                <div class="col-2 ">
                    <validated-vue-select class="c-input-select"
                                          :options="batchOption" @input="loadAnimalOption"
                                          label="Batch" v-model="model.batch"></validated-vue-select>
                </div>

                <div class="col-2 ">
                    <validated-vue-select class="c-input-select field-required" label="Animal"
                                          :options="animalOption"
                                          v-model="model.bull" :rules="{required:true}"></validated-vue-select>
                </div>
                <div class="col-1 pl-4 pt-4 mt-1">
                    <div class="row">
                        <btn text="Add" :loading="loading" loading-text="Adding..." class="px-4"></btn>
                    </div>
                </div>
            </div>
        </s-form>
        <div class="row">
            <div class="col-3 pl-0 pr-0 pt-0 pb-0" v-for="(item,i) in batchList" :key="i">
                <div class="card pl-0 pr-0 pt-0 pb-0 mt-5">
                    <table style="width:100%">
                        <thead>
                        <th class="text-primary" :class="{'br-black': i< batchList.length-1 && i!==3} ">{{ item.batch_name }}</th>
                        </thead>
                        <tbody>
                        <tr v-for="(j,k) in item.bulls" :key="k">
                            <td class="" :class="{'br-black': i< batchList.length-1 && i!==3}">{{ j.reg_no }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
        <div class="column fl-x-br pt-7">
            <!--            <btn text="Save" @click="saveData" class="px-4"></btn>-->
            <btn class="ml-2" design="basic-b" type="reset" @click="BackToHome" text="Cancel"></btn>
        </div>
    </div>
</template>

<script>
import masterURLs from '../../data/masterURLs';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'CollectionSchedule1',
    data () {
        return {
            URL: urls.collectionSchedule.addEdit,
            loading: false,
            batchOption: [],
            shedOptionURL: masterURLs.master.shed.vueSelect + '?animal=bull',
            typeOptions: [
                { label: 'Training', value: 'Training' },
                { label: 'Regular', value: 'Regular' }
            ],
            animalOption: [],
            batchList: [],
            model: {
                bull: '',
                collection_type: '',
                batch: '',
                shed_number: '',
                animal: ''

            }
        };
    },
    mounted () {
        this.loadBatchList();
    },
    methods: {
        clearData () {
            this.model.bull = '';
            this.model.collection_type = '';
            this.model.batch = '';
            this.model.shed_number = '';
            this.model.animal = '';
            this.$refs.form.reset();
        },
        BackToHome () {
            this.$router.push({ path: '/semen-station/' });
        },
        async loadBatchList () {
            const response = await axios.get(urls.collectionSchedule.batchList);
            this.batchList = response.data.data;
            console.log('BatachList', this.batchList);
        },
        async loadBatchOption () {
            const response = await axios.get(masterURLs.master.batch.vueSelect + '?shed_number=' + this.model.shed_number);
            this.batchOption = response.data.data;
        },
        async loadAnimalOption () {
            const response = await axios.get(urls.collectionSchedule.collectionBulls);
            this.animalOption = response.data.data;
        },
        async saveData () {
            await this.$router.push({ path: '/semen-station/' });
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.clearData();
                    await this.loadBatchList();
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped lang="scss">
    h2 {
        color: #3954A4;
    }

    h6 {
        color: #3954A4;
    }

    table {
        background-color: #f3f3f3;
        text-align: center;

    }

    th {
        background-color: white;
        border: 0px;
        padding-top: 0.9rem;
        padding-bottom: 0.9rem;
        padding-left: 18px;
    }

    td {
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
    }

    table, td, tr {
        border: none;
        border-collapse: collapse;
    }

    table, th, tr {
        border-radius: 2px;
    }

    .c-table-b-r {
        th {
            border-right: 1px solid black !important;
        }
    }

    .br-black {
        border-right: 1px solid black !important;
    }

    //.v1 {
    //   border-right: 1px solid black;
    //}

</style>
